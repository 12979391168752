.container {
  width: 100%;
  text-align: center;
}

.wrapper {
  position: relative;
  display: inline-block;
  width: auto;
}

.credit {
  color: #fff;
  opacity: 0.8;
  position: absolute;
  left: .2em;
  bottom: 0;
}

.text {
  width: 80%;
  color: #fff;
  font-family: sans-serif;
  font-size: 3em;
  line-height: 1.2em;
  font-weight: bold;
  position: absolute; 
  text-shadow: 0 0 .2em rgb(255, 0, 255), 0 0 .3em rgb(0, 255, 0);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.text:nth-child(1) {
  animation-name: text1;
}

.text:nth-child(2) {
  animation-name: text2;
}

.text:nth-child(3) {
  animation-name: text3;
}

.text:nth-child(4) {
  animation-name: text4;
}

.text:nth-child(5) {
  animation-name: text5;
}

@keyframes text1 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}

@keyframes text2 {
  20% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}

@keyframes text3 {
  40% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}

@keyframes text4 {
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}

@keyframes text5 {
  80% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}