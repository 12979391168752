.container {
  position: relative;
}

.refine {
  padding: 2em;
}

.refresh {
  position: absolute;
  top: 5px;
  right: 5px;
}