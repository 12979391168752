.message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.message > :not(:last-child) {
  margin-right: .5rem;
}

.assignmentType {
  font-weight: bold;
}

.info {
  position: fixed;
  top: 0;
  right: 0;
}

.refresh {
  position: absolute;
  right: 5px;
}