.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.volume {
  height: 100%;
  width: var(--assignment-width);
}

.volume > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.volume > div > div:first-child {
  flex: 1 0 auto;
}

.progressBar:last-child {
  margin: 0 !important;
}

.assignmentsHeader {
  margin-bottom: .2em !important;
}

.labels {
  display: flex;
}

.labels > * {
  flex: 1 1 auto;
}

.loadButton {
  width: 100%;
}

.chooseButton {
  margin-top: .8em;
}

.trainingInfo {
  margin-top: 5px;
}

.trainingInfo span {
  color: #000;
}