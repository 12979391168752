.verticalSlider {
  width: 100%;
  height: 100%;
  min-width: 30px;
}

@media only screen and (min-width: 768px) {
  .verticalSlider {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
  }
}